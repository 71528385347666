<template>
  <div class="account">
    <div class="cover"></div>
    <div class="container">
      <div class="row">
        <div class="d-none d-lg-block col-lg-6 align-self-center">
          <h1 class="info">Reise, so wie du bist.</h1>
        </div>
        <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-2">
          <div class="card">
            <h4 class="card-title">Neues Passwort festlegen</h4>
            <p>Dein neues Passwort muss sich vom alten unterscheiden.</p>
            <div class="old-pwd-container form">
              <div class="form-group">
                <span class="material-icons light left">lock</span>
                <input type="password" class="form-control icon-left lg"  @blur="validateOldPasswordInput" v-model="credentials.oldPassword" placeholder="Altes Passwort"/>
              </div>
              <span class="validation-error" v-show="showOldPasswordError">Geben Sie Ihr bisheriges Passwort ein. Das Passwort muss mindestens 8 Zeichen lang sein und mindestens eine Zahl enthalten.</span>
            </div>
            <div class="new-pwd-container">
              <div class="form-group">
                <span class="material-icons light left">lock</span>
                <input type="password" class="form-control icon-left lg"  @blur="validatePasswordInput" v-model="credentials.password" placeholder=" Neues Passwort"/>
              </div>
              <span class="validation-error" v-show="showPasswordError">Geben Sie Ihr Passwort ein. Das Passwort ist mindestens 8 Zeichen lang und enthält mindestens eine Zahl.</span>
            </div>
            <div class="new-pwd-container">
              <div class="form-group">
                <span class="material-icons light left">lock</span>
                <input type="password" class="form-control icon-left lg"  @blur="validatePasswordRepeatInput" v-model="credentials.passwordRepeat" placeholder=" Neues Passwort wiederholen"/>
              </div>
              <span class="validation-error" v-show="showPasswordRepeatError">Die Passwörter stimmen nicht überein.</span>
              <span class="validation-error" v-show="samePasswordError">Ihr neues Passwort muss sich von dem alten Passwort unterscheiden.</span>
            </div>

            <button class="btn btn-block" @click="handleChangePassword">Passwort ändern</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  import { csrf, setNewPassword } from '@/api/auth';
  import { validatePassword } from '@/utils/validate';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
    name: 'changePasswort',
    data() {
      return {
       credentials: {
        oldPassword: '',
        password: "",
        passwordRepeat: "",
      },
      showPasswordError: false,
      showOldPasswordError: false,
      showPasswordRepeatError: false,
      samePasswordError: false,
      loader: false,
    };
  },
  methods: { 
    showLoader,
    hideLoader,
    validatePassword,
    validatePasswordInput(){
      if(!this.validatePassword(this.credentials.password)){
        this.showPasswordError=true;
        return false;
      }else{
        this.showPasswordError=false;
        return true;
      }
    },
    validateOldPasswordInput(){
      if(!this.validatePassword(this.credentials.oldPassword)){
        this.showOldPasswordError=true;
        return false;
      }else{
        this.showOldPasswordError=false;
        return true;
      }
    },
    validatePasswordRepeatInput(){
      if(this.credentials.password != this.credentials.passwordRepeat){
        this.showPasswordRepeatError=true;
        return false;
      }else{
        this.showPasswordRepeatError=false;
        return true;
      }
    },
    validateOldNewPassword(){
      if(this.credentials.oldPassword === this.credentials.password){
        this.samePasswordError = true;
        return false;
      } else {
        this.samePasswordError = false;
        return true;
      }
    },
    handleChangePassword() {
      var validateOldNewPassword = this.validateOldNewPassword();
      var oldPasswordValid = this.validateOldPasswordInput();
      var passwordValid = this.validatePasswordInput();
      var passwordRepeatValid = this.validatePasswordRepeatInput();
      if(!passwordValid || !passwordRepeatValid || !oldPasswordValid || !validateOldNewPassword){
        return;
      }

      this.loader = this.showLoader(this.loader);
      csrf().then(() => {
        setNewPassword(this.credentials).then(() => {
         this.$notify({
          duration: 3500,
          title: 'Passwort geändert!',
          text: 'Ihr Passwort wurde erfolgreich geändert. Sie können sich jetzt mit Ihrem neuen Passwort anmelden.'
        });

         this.$router.push({name:'EditProfile'});

       })
        .catch(error => {
          var message = error.response.data;
          this.$notify({
            type: 'error',
            duration: 3500,
            title: 'Änderung fehlgeschlagen!',
            text: message
          });
        }).finally(()=>{
         this.loader = this.hideLoader(this.loader);
       });
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .validation-error{
    color:#E5321B;
    font-size: 13px;
    text-align: center;
  }

  .new-pwd-container {
    margin-bottom: 1rem;
  }

  .old-pwd-container {
    margin-bottom: 3rem;
  }

  .form-group {
    margin-bottom: 0 !important;
  }
</style>